import React from 'react'
import {TouchableOpacity} from 'react-native'
import { PDFDownloadLink, Page, Text, View, Document,Font,StyleSheet } from '@react-pdf/renderer';
import {styles} from '../../../assets/styleSAP/formaInputSap'
import '../../../../index.css'

const P =({items,dateofmeals})=>{
     const Almarai_Regular = require('../../../assets/fonts/Almarai/Almarai-Regular.ttf');
     Font.register({ family: 'Almarai-Regular', src: Almarai_Regular });

    function getDateAsString(dateofmeals) {
      const today = new Date(dateofmeals);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      return dateString;
    }
    const stylesTitle = StyleSheet.create({
        title: {
          fontFamily:"Almarai-Regular",
          fontSize:4
        },

    formaView:{
      borderWidth:0.5,
      marginBottom:55
   
  },
      })
    //   const MyPDF = () => {
    //     return (
    //       <Document>
    //         <Page size="A4">
    //           <View>
    //             <Text style={styles.title}>Hello, World!</Text>
    //           </View>
    //         </Page>
    //       </Document>
    //     );
    //   };

      const MyPDF = () => {
        return (
            <Document>

            {items.map((item, index) => (
              //<Page size={{ width: 200, height: 200 }}
             //<Page size={{ width: 1080}} >
             <Page size={{ width: 100, height: 90 }} >
<View style={{width:100,height:90 ,borderWidth:0.2,borderColor:"black",padding:0.5,paddingHorizontal:4}}>
  <View style={{margin:0.5}} >
  <Text style={{fontSize:4}}>Box-{item.id} |  Zone{item.zoneId}  |  Date : {dateofmeals} | PID : {item.package_id}
 </Text> 
  </View>
 <Text style={{fontSize:4,margin:0.5}}>meals : {item.meals} |
 Snacks : {item.snacks} | Protein : {item.protein}  | Carb : {item.carbs} 

 </Text> 



    
 {item.mealsChef.map((meal, index) => (
 <Text style={{fontSize:4,fontFamily:"Almarai-Regular",margin:0.5}} >
  * MEAL : {meal.itemNameEN}</Text>

                  )
                  )}

{item.snacksCehf.map((snack, index) => (
 <Text style={{fontSize:4,fontFamily:"Almarai-Regular",margin:0.5}} >
  * SNACK : {snack.itemNameEN}</Text>


                  )
                  )}

                 {/*line view */} 
                 <View style={{borderWidth:0.2,margin:0.5,width:"90%",
                marginBottom:2
                }} />
              {
                

                item.notes.replace(/\\n/g, '\n').split('\n').map((note, index) => (
        <Text key={index} style={{fontSize:4,margin:0.5, fontWeight:"bold",fontFamily:"Almarai-Regular"}}>
            * {note} 
        </Text>
    ))
}

<Text style={{fontSize:4,margin:0.5,fontWeight:"bold",fontFamily:"Almarai-Regular"}}>* Name : {item.name}

</Text>

<Text style={{fontSize:4,margin:0.5,fontWeight:"bold",fontFamily:"Almarai-Regular"}}> * Phone :{item.phone}
| Phone Of Recept :{item.phoneOfRecept}

</Text>


                

                  <Text style={{fontSize:3.7,fontFamily:"Almarai-Regular",margin:0.5}}>{"("}{item.area_nameEN} - {item.area_nameAR} {")"} </Text>
                  <Text style={{fontSize:3.7,fontFamily:"Almarai-Regular",margin:0.5}}>
                  {"("} Bl {item.block} {")"}  {"("}St {item.street} {")"}  {"("} J {item.jadah}{")"}  {"("} Ho {item.home}{")"}
                    {" ("}Fl {item.floor}{")"}  {"("} Flat {item.flat}{")"}
 </Text> 

















 </View>   
 
              </Page> 
            ))}
          </Document>
        );
      };
    return(
        <View>
           
            <PDFDownloadLink document={<MyPDF />} fileName={"MealBoxLabel"+dateofmeals+".pdf"}
            
        >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Print PDF'
        }
      </PDFDownloadLink>

        </View>
    )
}

export default P;